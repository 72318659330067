<script setup lang="ts">
  const props = defineProps<{
    id: string
    initOpen?: boolean
    title?: string | null | undefined
    onToggle?: () => void | null | undefined
  }>()

  defineOptions({ inheritAttrs: false })

  //the open slot prop from the HLDisclosure is negated before
  //being passed into this method because at the time of click
  //it is in the opposite state that it will be when toggling is complete
  const onToggle = (open: boolean) => {
    props.onToggle?.(open)
  }
</script>

<template>
  <HlDisclosure v-slot="{ open }" :default-open="initOpen ?? false">
    <div :class="['collapsible', $attrs.class]">
      <HlDisclosureButton
        as="div"
        :id
        :class="['collapsible-header']"
        @click="() => onToggle(!open)"
      >
        <div :class="{ 'collapsible-title': true }">
          <slot name="header" v-bind="{ open }"> </slot>
        </div>
        <button :class="{ 'collapsible-toggle': true, 'toggle-open': open }">
          <slot name="toggle" v-bind="{ open }">
            <HpIcon name="circle-caret" />
          </slot>
        </button>
      </HlDisclosureButton>
      <TransitionSlide
        appear
        :mode="'out-in'"
        :enter="{
          from: {
            y: -20
          },
          duration: 0.3
        }"
        :leave="{
          to: {
            y: -20
          },
          duration: 0.1
        }"
      >
        <HlDisclosurePanel :class="{ 'collapsible-panel': true }">
          <slot> </slot>
        </HlDisclosurePanel>
      </TransitionSlide>
    </div>
  </HlDisclosure>
</template>

<style scoped>
  .collapsible {
    @apply flex w-full flex-col;

    .collapsible-header {
      @apply relative flex w-full items-center justify-between gap-x-2;

      .collapsible-toggle {
        @apply absolute -right-7 flex transition-transform duration-500;

        &.toggle-open {
          @apply rotate-180;
        }
      }

      .collapsible-title {
        @apply flex w-full flex-col justify-between text-primary-900 md:flex-row;
      }
    }
  }
</style>
